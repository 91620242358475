export default {
  shared: {
    appName: 'RAIXER',
    homeScreen: 'Home',
    add: 'Add',
    active: 'Active',
    all: 'All',
    inactive: 'Inactive',
    autoAccess: 'Auto Access',
    openClosed: 'Open/Closed',
    cancel: 'Cancel',
    delete: 'Delete',
    done: 'Done',
    edit: 'Edit',
    from: 'From',
    offline: 'Offline',
    retry: 'Retry',
    save: 'Save',
    to: 'To',
    updating: 'Updating...',
    email: 'Email',
    password: 'Password',
    newPassword: 'New password',
    name: 'Name',
    displayName: 'Complete name',
    terms: 'Terms & Conditions',
    termsAbbr: 'T & C',
    profile: 'Profile',
    profileLabel: 'Profile',
    upgrades: 'Upgrades',
    credit: 'Credit',
    support: 'Support',
    logout: 'Logout',
    device: 'Device',
    devices: 'Devices',
    intercom: 'Intercom',
    power: 'Power',
    settings: 'Settings',
    phone: 'Phone',
    codes: 'Codes',
    users: 'Users',
    history: 'History',
    days: 'days',
    day: 'day',
    open: 'Open',
    close: 'Close',
    opened: 'Open',
    closed: 'Closed',
    door: 'Door',
    doors: 'Doors',
    piston: 'Piston',
    on: 'On',
    off: 'Off',
    openings: 'openings',
    yes: 'Yes',
    no: 'No',
    configuration: 'Configuration',
    schedule: 'Schedule',
    start: 'Start',
    finish: 'Finish',
    owner: 'Owner',
    user: 'User',
    admin: 'Admin',
    installer: 'Installer',
    enabled: 'Enabled',
    disabled: 'Disabled',
    me: 'Me',
    userList: 'User list',
    info: 'Info',
    share: 'Share',
    call: 'Call',
    campaign: 'Campaign',
    qrValidity: 'Valid for 15 days',
    selectUserType: 'Select a user type',
    any: 'Any',
    country: 'Country',
    general: 'General',
    led: 'LED',
    ledBrightness: 'LED brightness',
    noSensor: 'No sensor',
    sensor: 'Sensor',
    sensors: 'Sensors',
    noise: 'Noise',
    apiUser: 'API User',
    paid: 'Paid',
    unpaid: 'Unpaid',
    euro: '€',
    month: 'Month',
    year: 'Year',
    privatePhone: 'Private phone',
    intercomPremium: 'Intercom premium',
    guestyIntegration: 'Guesty integration',
    payment: 'Payment',
    plan: 'Plan',
    pay: 'Upgrade',
    unsubscribe: 'Remove upgrade',
    deviceInformation: 'Device information',
    deviceId: 'Device ID',
    productVersion: 'Device version',
    osVersion: 'Device OS version',
    firmwareVersion: 'Device firmware version',
    deviceIdHelp: 'Share this with our Support Team in case you are having any problem with your device',
    movement: 'Movement',
    proximity: 'Proximity',
    none: 'None',
    left: 'Left',
    right: 'Right',
    middle: 'Middle',
    invert: 'Invert',
    variableNotFound: 'Not working right now',
    movementFound: 'Movement detected',
    movementNotFound: 'No movement detected',
    street: 'Street',
    home: 'Home',
    garage: 'Garage',
    other: 'Other',
    otherUse: 'Other use',
    dontUseSensor: 'Don\'t use this sensor',
    autoAccessDoorbell: 'Auto Access doorbell',
    openClosedSensor: 'Open/Closed sensor',
    up: 'Up',
    bottom: 'Bottom',
    bottomAndLeft: 'Bottom and left',
    upAndRight: 'Up and right',
    autoCalibrate: 'Auto Calibrate',
    calibrate: 'Calibrate',
    advanced: 'Advanced',
    default: 'Default',
    unitMillivolts: ' millivolts [mV]',
    unitMillivoltsSimple: ' millivolts',
    unitSeconds: ' seconds [s]',
    unitSecond: ' second [s]',
    unitSecondsSimple: ' seconds',
    unitSecondSimple: ' second',
    unitOpenings: ' openings',
    unitDecibels: ' decibels [dB]',
    unitMinutes: ' minutes [m]',
    unitHours: ' hours [h]',
    electricity: 'Electricity',
    sound: 'Sound',
    back: 'Back',
    documentNumber: 'Document number',
    city: 'City',
    province: 'Province',
    zipCode: 'Zip code',
    emailBilling: 'Email for invoicing',
    progress: 'Progress',
    public: 'Public',
    private: 'Private',
    showPassword: 'Show password',
    remainAs: 'Remain as',
    notUpgraded: 'Not upgraded',
    upgrade: 'Upgrade',
    myCredit: 'My credit',
    transactions: 'Transactions',
    addCredit: 'Add credit',
    noInfo: 'No information',
    creditAdded: 'Credit added',
    connection: 'Connection',
    minutes: 'Minutes',
    accumulated: 'Accumulated',
    enable: 'Enable',
    integrations: 'Integrations',
    integrated: 'Integrated',
    notIntegrated: 'Not integrated',
    integrate: 'Integrate',
    valid: 'Valid',
    pair: 'Pair',
    pairedWith: 'Paired with',
    paired: 'Paired',
    notPaired: 'Not paired',
    replacePair: 'Replace',
    unauthorizedDevice: 'Another user device',
    wentOffline: 'Your device went offline aprox. at:',
    updateAvailable: 'Update available',
    upToDate: 'Up to date',
    weekdays: 'M,T,W,T,F,S,S',
    hours: 'Hours',
    address: 'Address',
    location: 'Location',
    booking: 'Bookings',
    guests: 'Guests',
    night: 'Night',
    nights: 'Nights',
    sequenceNumber: 'Number',
    checkin: 'Checkin',
    checkinIntegration: 'Checkin integration',
    phoneCallAction: 'Calling this number will open the door',
    phoneCallActionCallBell: 'Calling this number will open the door if the intercom bell just rang',
    nuki: 'Nuki',
    deleteAndUnpair: 'Unpair',
    key: 'Key',
    exhibitor: 'Exhibitor',
    extras: 'Extras',
    pushButton: 'Tap',
    energySaveMode: 'Power Save',
    test: 'Test',
    facebook: 'Facebook',
    buy: 'Buy',
    networkName: 'Network name',
    store: 'Store',
    in: 'In',
    today: 'Today',
    remaining: 'Remaining',
    expiresToday: 'Expires today',
    restOfDay: 'The rest of the day',
    paymentFailing: 'Your card was declined, update it.',
    retryPayment: 'Retry',
    exit: 'Exit',
    checkinGuide: 'Checkin guide',
    numberOfSublocations: 'Accepted reservations',
    numberOfSublocationsHelp: 'Number of reservations allowed at the same time. For example:\n- For a 7-room hotel, set this number to 7. The rooms are booked separately.\n- For a 3-room tourist apartment, set this number to 1. The rooms are not booked separately.',
    trialDaysRemaining: 'Number of trial days',
    notifications: 'Notifications',
    pushNotifications: 'Push notifications',
    now: 'Now',
    unauthorized: 'Unauthorized',
    visitCode: 'Open link',
    getQR: 'Get QR',
    codeCallAction: 'Link to open the door',
    openCode: 'Open with code',
    copy: 'Copy',
    availableOnPhone: 'Also available on your phone',
    checkinConditions: 'Conditions',
    generalSettings: 'General Settings'
  },
  snacks: {
    errors: {
      asyncStorage: 'Unexpected error accesing your browser storage',
      login: 'Incorrect email/password combination',
      recover: 'There is no user associated with this email',
      sessionExpired: 'Your session has expired. Please, login again.',
      startupConfiguration: 'Unexpected error. Please, login again.',
      register: 'There is already an account with this email',
      facebook: 'Unexpected error doing the login with Facebook. Please, try again',
      connectionsListStartTask: 'Unexpected error starting Auto Access',
      connectionsListStopTask: 'Unexpected error stopping Auto Access',
      connectionsDetailStartTask: 'Unexpected error starting Auto Access',
      connectionsDetailStopTask: 'Unexpected error stopping Auto Access',
      connectionsDetailActivatePistonTask: 'Unexpected error activating Piston',
      connectionsDetailDeactivatePistonTask: 'Unexpected error deactivating Piston',
      connectionsDetailOpenDoorTask: 'Unexpected error opening door',
      connectionsDetailGyroOpenDoorTaskStarted: 'Unexpected error opening the Gyro',
      connectionsDetailGyroCloseDoorTaskStarted: 'Unexpected error closing the Gyro',
      connectionsDetailNukiOpenDoorTaskStarted: 'Unexpected error opening the Nuki',
      connectionsDetailNukiActivatePistonTaskStarted: 'Unexpected error closing the Nuki',
      connectionsDetailNukiDeactivatePistonTaskStarted: 'Unexpected error unlocking the Nuki',
      connectionsDetailNukiOpenDoorTaskFinished: 'The Nuki could not open',
      connectionsDetailNukiActivatePistonTaskFinished: 'The Nuki could not close',
      connectionsDetailNukiDeactivatePistonTaskFinished: 'The Nuki could not unlock',
      connectionsDetailGetHistory: 'Unexpected error obtaining the history of the device. Please, try again',
      connectionsDetailGetVitals: 'Unexpected error obtaining the signal of the device. Please, try again',
      connectionsDetailSaveSchedule: 'Unexpected error saving your schedule. Please, try again',
      connectionsDetailDeleteSchedule: 'Unexpected error deleting your schedule. Please, try again',
      connectionsDetailSaveUser: 'Unexpected error saving the user. Please, try again',
      connectionsDetailDeleteUser: 'Unexpected error deleting the user. Please, try again',
      // connectionsDetailEnableUser: 'Unexpected error enabling the user. Please, try again',
      // connectionsDetailDisableUser: 'Unexpected error disabling the user. Please, try again',
      connectionsDetailSaveAuthorizedPhone: 'Unexpected error saving the authorized phone. Please, try again',
      connectionsDetailDeleteAuthorizedPhone: 'Unexpected error deleting the authorized phone. Please, try again',
      connectionsDetailCallError: 'We\'re sorry, your device does not support this functionality',
      connectionsDetailEnableDoorPhone: 'Unexpected error enabling the phone access for the door. Please, try again',
      connectionsDetailDisableDoorPhone: 'Unexpected error disabling the phone access for the door. Please, try again',
      connectionsDetailAutoAccessConfiguration: 'Unexpected error saving the Auto Access configuration',
      connectionsDetailGetDoorPhone: 'Unexpected error setting the phone for the door. Please, try again',
      connectionsDetailGetDoorPhoneAddAuthorizedPhone: 'The phone for the door was set but the new authorized phone could not be saved. Please, try again',
      connectionsDetailDeleteDoorPhone: 'Unexpected error removing the phone from the door. Please, try again',
      connectionsDetailAnyCallerDoorPhone: 'Unexpected error. Please, try again',
      // connectionsDetailSettingsDelete: 'Unexpected error removing your device. Please, try again',
      getUser: 'Unexpected error obtaining your account details. Please, go back and try again',
      updateUser: 'Unexpected error updating your info. Please, try again',
      // getStripeCustomer: 'Unexpected error obtaining your payment details. Please, go back and try again',
      // addStripeCard: 'Unexpected error saving your credit card. Please, try again',
      // updateStripeCard: 'Unexpected error updating your credit card. Please, try again',
      // deleteStripeCard: 'Unexpected deleting your credit card. Please, try again',
      // addStripeSubscription: 'Unexpected error upgrading your device. Please, try again',
      // updateStripeSubscription: 'Unexpected error updating your device upgrade. Please, try again',
      // deleteStripeSubscription: 'Unexpected error deleting your device upgrade. Please, try again',
      // addStripePhoneSubscription: 'Unexpected error upgrading your phone. Please, try again',
      // updateStripePhoneSubscription: 'Unexpected error updating your phone upgrade. Please, try again',
      // deleteStripePhoneSubscription: 'Unexpected error deleting your phone upgrade. Please, try again',
      // connectionsDetailSettingsGeneralSave: 'Unexpected error saving your device general settings. Please, try again',
      // connectionsDetailSettingsGeneralPowerSaveTest: 'Unexpected error sleeping your device. Please, try again',
      // connectionsDetailSettingsSensorSave: 'Unexpected error saving your sensor. Please, try again',
      // connectionsDetailSettingsSensorDelete: 'Unexpected error deleting your sensor. Please, try again',
      // connectionsDetailSettingsDoorSave: 'Unexpected error saving your door. Please, try again',
      // connectionsDetailSettingsDoorDelete: 'Unexpected error deleting your door. Please, try again',
      // wizardScanQRCodeNotValid: 'Sorry, we didn\'t recognize a valid Raixer QR code. Please, try again',
      // wizardScanQR: 'Unexpected error adding the device to your account. Please, try again',
      // wizardScanQRExistingConnection: 'Sorry, you already have a connection to this device.',
      // wizardScanQRCodeNotFound: 'Sorry, the QR you scanned was not found.',
      // wizardScanQRCodeInvalid: 'Sorry, the QR you scanned is no longer valid or was already used.',
      // wizardScanQRDeviceOffline: 'The device is not turned on or does not have internet. Please, turn on your device and make sure the light is breathing turquoise before trying again.',
      // wizardAddDeviceConnections: 'The device was connected to the internet and added to your account, if applies, but we can\'t get your devices right now. Please, try reloading your devices again.',
      // wizardAddDeviceNoiseSensorDeviceInUse: 'The device you are trying to add as a noise sensor is already in use, so you can\'t add it to your device.',
      // wizardAddDeviceWifiUnexpected: 'Unexpected error. Restart the device manually and try again',
      // osCantOpenSettings: 'Sorry, we can\'t open your phone settings. Please, go there manually.',
      connectionsDetailUnblockOpenClosed: 'Unexpected error unblocking your open/closed sensor',
      connectionsDetailUnblockAutoAccess: 'Unexpected error unblocking the Auto Access mode',
      connectionsDetailUpdateDeviceFirmware: 'Unexpected error starting the update of your device',
      // platformIntegrationRequest: 'You haven\'t integrated the platform yet. Please, finish the process',
      // platformIntegrationSave: 'Unexpected error saving your integration',
      // platformIntegrationSaveGuestyUnauthorized: 'Sorry, the Guesty API Token you provided is not valid',
      // guestyUnexpected: 'Unexpected error obtaining your Guesty integration',
      // guestyListings: 'Unexpected error obtaining your Guesty listings. Please, try again',
      // pairGuestyListing: 'Unexpected error pairing your listing. Please, try again',
      // deleteGuestyListingPair: 'Unexpected error unpairing your listing. Please, try again',
      connectionLocationSave: 'Unexpected error saving your location. Please, try again',
      connectionLocationDelete: 'Unexpected error deleting your location. Please, try again',
      connectionBookingSave: 'Unexpected error saving your booking. Please, try again',
      connectionBookingDelete: 'Unexpected error deleting your booking. Please, try again',
      connectionBookingOverlapping: 'The checkin or checkout date conflicts with an existing booking. Please correct the data and try again.',
      getGuestBook: 'Unexpected error loading your guest book. Please, try again',
      sendGuestBook: 'Unexpected error sending guest book. Please, try again',
      // getBookings: 'Unexpected error getting bookings. Please, try again',
      // addCheckinSubscription: 'Unexpected error upgrading your device. Please, try again',
      // updateCheckinSubscription: 'Unexpected error updating your device upgrade. Please, try again',
      // deleteCheckinSubscription: 'Unexpected error deleting your device upgrade. Please, try again',
      // connectionPairLocation: 'Unexpected error pairing your device. Please, try again',
      // retryPayment: 'Your card was declined. Please, update it and try again',
      // connectionSaveDeviceConfigurationWizard: 'Unexpected error saving your selection about the device configuration process. Please, try again',
      connectionsDetailGetDoorCode: 'Unexpected error setting the code for the door. Please, try again',
      connectionsDetailSaveAuthorizedCode: 'Unexpected error saving the authorized code. Please, try again',
      connectionsDetailUpdateAuthorizedCode: 'Unexpected error updating the authorized code. Please, try again',
      connectionsDetailDeleteAuthorizedCode: 'Unexpected error deleting the authorized code. Please, try again',
      connectionsDetailEnableDoorCode: 'Unexpected error enabling the access by code for the door. Please, try again',
      connectionsDetailDisableDoorCode: 'Unexpected error disabling the access by URL for the door. Please, try again',
      connectionsDetailDeleteDoorCode: 'Unexpected error removing the link from the door. Please, try again',
      profileGeneralFormAccountExists: 'An account with this email already exists',
      profileGeneralFormNoPendingChange: 'No pending email change',
      profileGeneralFormVerificationExpired: 'The verification code has expired',
      profileGeneralFormInvalidCode: 'Invalid verification code'
    },
    success: {
      register: 'Great! Now please check your email and verify your account',
      recover: 'Great! Check your email to verify your new password',
      connectionsListStartTask: 'Auto Access started successfully',
      connectionsListStopTask: 'Auto Access stopped successfully',
      connectionsDetailStartTask: 'Auto Access started successfully',
      connectionsDetailStopTask: 'Auto Access stopped successfully',
      connectionsDetailActivatePistonTask: 'Piston activated successfully',
      connectionsDetailDeactivatePistonTask: 'Piston deactivated successfully',
      connectionsDetailOpenDoorTask: 'Door opened successfully',
      connectionsDetailGyroOpenDoorTaskStarted: 'Gyro opening...',
      connectionsDetailGyroCloseDoorTaskStarted: 'Gyro closing...',
      connectionsDetailNukiOpenDoorTaskStarted: 'Nuki opening...',
      connectionsDetailNukiActivatePistonTaskStarted: 'Nuki closing...',
      connectionsDetailNukiDeactivatePistonTaskStarted: 'Nuki unlocking...',
      connectionsDetailNukiOpenDoorTaskFinished: 'Nuki opened successfully',
      connectionsDetailNukiActivatePistonTaskFinished: 'Nuki closed successfully',
      connectionsDetailNukiDeactivatePistonTaskFinished: 'Nuki unlocked successfully',
      connectionsDetailSaveSchedule: 'Schedule saved successfully',
      connectionsDetailDeleteSchedule: 'Schedule deleted successfully',
      connectionsDetailSaveUser: 'User saved successfully',
      connectionsDetailDeleteUser: 'User deleted successfully',
      // connectionsDetailEnableUser: 'User enabled successfully',
      // connectionsDetailDisableUser: 'User disabled successfully',
      connectionsDetailSaveAuthorizedPhone: 'Authorized phone saved successfully',
      connectionsDetailDeleteAuthorizedPhone: 'Authorized phone deleted successfully',
      connectionsDetailEnableDoorPhone: 'Phone access for the door enabled successfully',
      connectionsDetailDisableDoorPhone: 'Phone access for the door disabled successfully',
      connectionsDetailAutoAccessConfiguration: 'Auto Access configuration saved successfully',
      connectionsDetailGetDoorPhone: 'The phone for the door was set successfully',
      connectionsDetailGetDoorPhoneAddAuthorizedPhone: 'The phone for the door was set successfully and the new authorized phone was saved',
      connectionsDetailDeleteDoorPhone: 'The phone from the door was removed successfully',
      // connectionsDetailSettingsDelete: 'Your device was removed successfully',
      updateUser: 'Your info was saved successfully',
      // addStripeCard: 'Your credit card was saved successfully',
      // updateStripeCard: 'Your credit card was updated successfully',
      // deleteStripeCard: 'Your credit card was deleted successfully',
      // addStripeSubscription: 'Your device upgrade was saved successfully',
      // updateStripeSubscription: 'Your device upgrade was updated successfully',
      // deleteStripeSubscription: 'Your device upgrade was deleted successfully',
      // addStripePhoneSubscription: 'Your phone upgrade was saved successfully',
      // updateStripePhoneSubscription: 'Your phone upgrade was updated successfully',
      // deleteStripePhoneSubscription: 'Your phone upgrade was deleted successfully',
      // connectionsDetailSettingsGeneralSave: 'Your device general settings were saved successfully',
      // connectionsDetailSettingsGeneralPowerSaveTest: 'Your device went asleep successfully',
      // connectionsDetailSettingsSensorSave: 'Your sensor was saved successfully',
      // connectionsDetailSettingsSensorDelete: 'Your sensor was deleted successfully',
      // connectionsDetailSettingsDoorSave: 'Your door was saved successfully',
      // connectionsDetailSettingsDoorDelete: 'Your door was deleted successfully',
      // wizardAddDeviceAdded: 'Great! The device was added to your account',
      // wizardAddDeviceUpdatedCellular: 'Great! Your device was already owned by you or another user so, we did not updated it.',
      // wizardAddDeviceUpdatedWifi: 'Great! Your device was already owned by you or another user so, we\'ve only updated it\'s WiFi.',
      // wizardAddDeviceAddedNoiseSensor: 'Great! Your new noise sensor was added to your device.',
      connectionsDetailUnblockOpenClosed: 'Open/closed sensor unblocked successfully',
      connectionsDetailUnblockAutoAccess: 'Auto Access mode unblocked successfully',
      connectionsDetailUpdateDeviceFirmware: 'Device update started successfully',
      // platformIntegrationRequest: 'Great! Email sent successfully',
      // platformIntegrationSave: 'Great! Your integration was saved successfully',
      // pairGuestyListing: 'Great! Your listing was paired successfully',
      // deleteGuestyListingPair: 'Great! Your listing was unpaired successfully',
      connectionLocationSave: 'Great! Your location was saved succesfully',
      connectionLocationDelete: 'Your location was deleted succesfully',
      connectionBookingSave: 'Great! Your booking was saved succesfully',
      connectionBookingDelete: 'Your booking was deleted succesfully',
      sendGuestBook: 'Great! Your guest book was sent succesfully',
      // addCheckinSubscription: 'Your device upgrade was saved successfully',
      // updateCheckinSubscription: 'Your device upgrade was updated successfully',
      // deleteCheckinSubscription: 'Your device upgrade was deleted successfully',
      // connectionPairLocation: 'Your device was paired successfully',
      // retryPayment: 'Great! Your payment was successsful',
      connectionsDetailSaveAuthorizedCode: 'Authorized code saved successfully',
      connectionsDetailUpdateAuthorizedCode: 'Authorized code updated successfully',
      connectionsDetailDeleteAuthorizedCode: 'Authorized code deleted successfully',
      connectionsDetailEnableDoorCode: 'Access by URL for the door enabled successfully',
      connectionsDetailDisableDoorCode: 'Access by URL access for the door disabled successfully',
      connectionsDetailGetDoorCode: 'The code for the door was set successfully',
      connectionsDetailDeleteDoorCode: 'The link from the door was removed successfully',
      connectionsDetailCopyDoorCode: 'Link copied'
    }
  },
  modals: {
    deviceOfflineIntercom: {
      title: 'Device offline or without internet',
      text: 'Please, make sure of the following:\n\n1. Your device is powered on.\n2. The WiFi is working correctly.\n3. The name and password of the WiFi Network have not changed.',
      buttons: {
        ok: 'Reconfigure WiFi'
      },
    },
    deviceOfflineAccess: {
      title: 'Device offline or without internet',
      text: 'Please, make sure of the following:\n\n1. Your device is powered on.\n2. There is mobile coverage in the place of the device.'
    },
    offlineAutoAccess: {
      title: 'Contingency Mode',
      textFirst: 'The Auto Access will be active only if your device is powered on.\n\nRemember that the Auto Access will deactivate after opening ',
      textSecond: ' times.\n\nYou can turn this feature off whenever you want.'
    },
    upgradeFeatureOwner: {
      title: 'Device upgrade needed',
      text: 'Your device is on the free version. You can\'t use this feature unless you upgrade.',
      buttons: {
        ok: 'Upgrade'
      }
    },
    upgradePhoneFeatureOwner: {
      title: 'Device upgrade needed',
      text: 'Your device is on the trial or free version. You can\'t use this feature unless you upgrade your device and then upgrade your phone to a private phone.',
      buttons: {
        ok: 'Upgrade'
      }
    },
    upgradeNotOwner: {
      title: 'Device upgrade needed',
      text: 'Your device is on the free version. You can\'t use this feature until the owner upgrades it. You can check who\'s the owner on the Users tab.'
    },
    upgradePhoneFeatureNotOwner: {
      title: 'Device upgrade needed',
      text: 'Your device is on the trial or free version. You can\'t use this feature unless your device is upgraded and your phone is upgraded to a private phone.\n\nOnly the owner of the device can upgrade it, you can check who\'s the owner on the Users tab.'
    },
    upgradeFeatureExhibitor: {
      title: 'Exhibitor device',
      text: 'Your device is an exhibitor and is not eligible for this feature.'
    },
    unlockFeatures: {
      title: 'Unlock features',
      text: 'Upgrade your device to unlock all of these features:\n\nScheduled Auto Access\nUnlimited Auto Access\nContingency mode\nUp to 10 authorized phone numbers\nAuthorized phone numbers by hours or days\nUnlimited app users\nDevice history',
      buttons: {
        ok: 'Upgrade'
      }
    },
    unlockFeaturesNotOwner: {
      title: 'Unlock features',
      text: 'Ask the owner of the device to upgrade your device to unlock all of these features:\n\nScheduled Auto Access\nUnlimited Auto Access\nContingency mode\nUp to 10 authorized phone numbers\nAuthorized phone numbers by hours or days\nUnlimited app users\nDevice history\n\nYou can check who\'s the owner on the Users tab.'
    },
    cantUse: {
      title: 'Device upgrade needed',
      text: 'Your trial period for this device has ended. Upgrade it to keep using it and unlock all of these features:\n\nScheduled Auto Access\nUnlimited Auto Access\nContingency mode\nUp to 10 authorized phone numbers\nAuthorized phone numbers by hours or days\nUnlimited app users\nDevice history',
      buttons: {
        ok: 'Upgrade'
      }
    },
    trialRunning: {
      title: 'Device trial',
      text: 'Your device is on trial. You can test all features without limit.\n\nWhen the trial ends, you will have to upgrade your device to keep using it.',
      textFreeVersion: 'Your device is on trial. You can test all features without limit.\n\nWhen the trial ends, your device will be on the free version.\n\nIf you want to enjoy all features without limit, you can upgrade.',
      buttons: {
        ok: 'Upgrade'
      }
    },
    trialRunningNotOwner: {
      title: 'Device trial',
      text: 'Your device is on trial. You can test all features without limit.\n\nWhen the trial ends, the owner of your device will have to upgrade it so you can keep using it. You can check who\'s the owner on the Users tab.',
      textFreeVersion: 'Your device is on trial. You can test all features without limit.\n\nWhen the trial ends, your device will be on the free version.\n\nIf you want to enjoy all features without limit, you can ask the owner of the device to upgrade it. You can check who\'s the owner on the Users tab.'
    },
    deviceVitals: {
      title: 'WiFi Signal',
      titleAlt: 'Cellular Signal',
      textFirst: 'Quality: ',
      textSecond: 'Strength: ',
      buttons: {
        ok: 'Reload',
      }
    },
    deviceVitalsBattery: {
      title: 'Battery',
      textFirst: 'Battery level: ',
      textSecond: 'Battery state: ',
      buttons: {
        ok: 'Reload',
      }
    },
    deviceMiniGyroVitalsBattery: {
      title: 'Gyro Battery',
      textFirst: 'Battery level: ',
      textSecond: 'Battery state: ',
      buttons: {
        cancel: 'Done',
      }
    },
    deleteSchedule: {
      title: 'Delete schedule',
      text: 'Are you sure you want to delete this schedule?',
      buttons: {
        ok: 'Delete'
      }
    },
    deleteUser: {
      title: 'Delete user',
      text: 'Are you sure you want to delete this user? The user will no longer be able to access this device.',
      buttons: {
        ok: 'Delete'
      }
    },
    disableUser: {
      title: 'Disable user',
      text: 'Are you sure you want to disable this user? The user will no longer be able to see this device.',
      buttons: {
        ok: 'Disable'
      }
    },
    updateUserOwner: {
      title: 'Make owner',
      text: 'Are you sure you want to make this user the owner of the device?',
      buttons: {
        ok: 'Yes, make owner'
      }
    },
    updateUserOwnerConfirm: {
      title: 'Make owner',
      text: 'Are you really sure?\n\nPlease, consider that the device will no longer be yours and all the data associated with it, including the subscriptions you have, will be lost.',
      buttons: {
        ok: 'I understand, make owner'
      }
    },
    deleteAuthorizedPhone: {
      title: 'Delete phone',
      text: 'Are you sure you want to delete this phone?',
      buttons: {
        ok: 'Delete'
      }
    },
    disableDoorPhoneAccess: {
      title: 'Disable phone access',
      text: 'Are you sure you want to disable phone access? The device won\'t open the door if anyone calls.',
      buttons: {
        ok: 'Disable'
      }
    },
    intercomCallFirst: {
      title: 'Open door',
      text: 'Please, make sure the intercom bell just rang before opening the door from here.',
      buttons: {
        ok: 'Open door'
      }
    },
    doorOpenConfirm: {
      title: 'Open door',
      text: 'Are you sure you want to open the door',
      buttons: {
        ok: 'Open door'
      }
    },
    doorCloseConfirm: {
      title: 'Close door',
      text: 'Are you sure you want to close the door',
      buttons: {
        ok: 'Close door'
      }
    },
    autoAccessBlocked: {
      title: 'Auto Access Always Active',
      text: '"Auto Access Always Active" Mode is on, so you can\'t turn off Auto Access from here.\n\nRemember, you can change this from Auto Access Configuration.'
    },
    alwaysActiveTurnedOn: {
      title: 'Always Active',
      text: 'The Auto Access will always be active, even when your device has no internet, and it will not have a max number of openings allowed.\n\nKeep in mind that this will only work if your device is powered on.\n\nNOTE: Your neighbors might not like this.',
      buttons: {
        cancel: 'Ok'
      }
    },
    contingencyModeTurnedOn: {
      title: 'Contingency Mode',
      textFirst: 'The Auto Access will be active only if your device is powered on.\n\nRemember that the Auto Access will deactivate after opening ',
      textSecond: ' times.\n\nYou can turn this feature off whenever you want.',
      buttons: {
        cancel: 'Ok'
      }
    },
    contingencyModeBlocked: {
      title: 'Contingency Mode',
      text: 'You have "Auto Access Always Active" turned on, you don\'t need "Contigency Mode" because Auto Access is always working, even when the device has no internet.',
      buttons: {
        cancel: 'Ok'
      }
    },
    autoAccessConfigurationUnaivalable: {
      title: 'Modes unavailable',
      text: 'Sorry, the Contingency or Always Active modes are not yet available for your device. In the next days your device will update automatically to get these modes.',
      buttons: {
        cancel: 'Ok'
      }
    },
    authorizedPhoneInAnotherDevice: {
      title: 'Authorized phone in use',
      text: 'Your authorized phone was saved but, just to inform you, we found it on the door of another of your devices.\n\nPlease, keep in mind that when this number calls it will open both doors.'
    },
    authorizedPhoneError: {
      title: 'Error saving phone',
      text: 'The authorized phone you provided could not be saved because an unexpected error ocurred or, maybe, because it is already on this door.',
      buttons: {
        cancel: 'Close'
      }
    },
    authorizedPhoneErrorDoorPhoneUnavailable: {
      title: 'Error saving phone',
      text: 'You have reached the maximum number of authorized phone numbers for a shared number.\n\nYou need a private phone in order to add more authorized phones.\n\nSadly, we don\'t have any phone numbers available. Please, contact us at hello@raixer.com'
    },
    accessToAnyCallerErrorDoorPhoneUnavailable: {
      title: 'Error access to any caller',
      text: 'The number you had can\'t be used for this feature because it was already in use by other devices.\n\nYou need a private phone in order to enable access to any caller.\n\nSadly, we don\'t have any availables phone numbers available. Please, contact us at hello@raixer.com',
    },
    doorPhoneRemove: {
      title: 'Remove door phone',
      text: 'Are you sure you want to remove the phone of your door?\n\nYou won\'t be able to open the door with phone calls and, if you want to enable the phone again, it probably won\'t be the same you have right now.',
      buttons: {
        ok: 'Yes, remove it'
      }
    },
    doorPhonePossibleAnswers: {
      title: 'Information about our phones',
      text: 'Remember! The door will open even if the answer to the call is:\n\n1. The call is rejected.\n2. The phone does not exist.',
      buttons: {
        ok: 'Got it'
      }
    },
    doorPhoneUseExistingPhone: {
      title: 'Use same phone',
      text: 'If you add the same authorized phone in different doors with the same phone number, it will open all doors with just one call.\n\nRemember that the door will open even if the answer to the call is:\n\n1. The call is rejected.\n2. The phone does not exist.',
      buttons: {
        ok: 'Got it'
      }
    },
    deleteConnection: {
      title: 'Delete device',
      text: 'Do you really want to delete this device from your account?',
      buttons: {
        ok: 'Delete this device'
      }
    },
    deleteConnectionOwner: {
      title: 'Delete device',
      text: 'You are the owner of this device. Consider that the device will no longer be yours and all the data associated with it will be lost.',
      buttons: {
        ok: 'Delete this device'
      }
    },
    deleteConnectionWithUsers: {
      title: 'Delete device',
      textFirst: '\u26D4\u26A0 IMPORTANT \u26A0\u26D4\n\nIf you continue, the following persons will lose access to the device:',
      textSecond: 'NOTE: If you are an installer, please, make sure the person who bought the device accepts it\'s ownership before you delete it.',
      buttons: {
        ok: 'I understand, delete this device'
      }
    },
    deleteConnectionWithQRs: {
      title: 'Delete device',
      text: '\u26D4\u26A0 IMPORTANT \u26A0\u26D4\n\nYou have active QR Codes for adding users or transfer ownership of the device. If you continue before somebody accepts them, everything associated with the device will be lost.\n\nNOTE: If you are an installer, please, make sure the person who bought the device accepts it\'s ownership before you delete it.',
      buttons: {
        ok: 'I understand, delete this device'
      }
    },
    deleteConnectionCheckin: {
      title: 'Delete checkin integration',
      text: 'Do you really want to delete this checkin integration from your account? Consider that all the data associated with it will be lost.',
      buttons: {
        ok: 'Delete checkin integration'
      }
    },
    addDoor: {
      title: 'Add device door',
      text: 'Do you really want to add a door to this device? Consider that this configuration should be made by an installer.',
      buttons: {
        ok: 'Confirm'
      }
    },
    editDoor: {
      title: 'Edit device door',
      text: 'Do you really want to edit the configuration of this door? Consider that this was probably made by an installer.',
      buttons: {
        ok: 'Confirm'
      }
    },
    addSensor: {
      title: 'Add device extra',
      text: 'Do you really want to add an extra to this device? Consider that this configuration should be made by an installer.',
      buttons: {
        ok: 'Confirm'
      }
    },
    editSensor: {
      title: 'Edit device extra',
      text: 'Do you really want to edit the configuration of this extra? Consider that this was probably made by an installer.',
      buttons: {
        ok: 'Confirm'
      }
    },
    deleteCreditCard: {
      title: 'Delete credit card',
      text: 'Are you sure you want to delete your card? You can add another at any moment.',
      buttons: {
        ok: 'Delete'
      }
    },
    addSubscription: {
      title: 'Device upgraded',
      text: 'Great! Your device has been upgraded. You\'ll be charged the amount of your selected plan tomorrow.',
      textReactivation: 'Great! Your device upgrade has been reactivated.'
    },
    deleteSubscription: {
      title: 'Device downgraded',
      withoutDate: {
        text: 'Your device has been downgraded. You won\'t be able to use it until you upgrade it again.'
      },
      withDate: {
        textFirst: 'Your device has been downgraded. You can keep using your device until ',
        textSecond: '. After this date, you won\'t be able to use your device until you upgrade it again.'
      }
    },
    dimmerUnaivalable: {
      title: 'Setting unavailable',
      text: 'Sorry, adjusting the LED light brightness is not yet available for your device. In the next days your device will update automatically and you will be able to adjust the light brightness.',
      buttons: {
        cancel: 'Ok'
      }
    },
    sensorMissingPort: {
      title: 'Missing port',
      text: 'Please, select a port for the sensor before trying this'
    },
    deleteSensor: {
      title: 'Delete extra',
      text: 'Are you sure you want to delete this extra?',
      buttons: {
        ok: 'Delete'
      }
    },
    invertedMovementSensor: {
      title: 'Inverted sensor',
      text: 'By configuring your movement sensor this way, you will not know if the cable of the sensor has been cut by someone.',
      buttons: {
        ok: 'I understand, save sensor'
      }
    },
    deleteDoor: {
      title: 'Delete door',
      text: 'Are you sure you want to delete this door?',
      buttons: {
        ok: 'Delete'
      }
    },
    deleteNukiDoor: {
      title: 'Unpair Nuki',
      text: 'Are you sure you want to unpair your Nuki from your Raixer device?',
      buttons: {
        ok: 'Unpair'
      }
    },
    goToDoorAdvanced: {
      title: 'Advanced',
      text: 'Here you can manually configure the values for the door. You can configure all this automatically pressing the "Auto Calibrate" button.',
      buttons: {
        ok: 'I understand, go to Advanced'
      }
    },
    pickUpFirstModeBlocked: {
      title: 'Picking up needed',
      text: 'We\'re sorry, but you can\'t enable this feature right now because your device has more than one door configured.\n\nIf you really need this feature, go back and delete the other door of your device before trying this again.'
    },
    openClosedBlockedByDevice: {
      title: 'Door open/closed sensor disabled',
      text: 'Your device is detecting more door openings per minute than our allowed limit.\n\nThis may indicate a bad connected between your device and the open/closed sensor or a misconfiguration of the door. We recommend you check the physical connection on the device and the configuration of the door.\n\nWhen you\'re sure the problem is fixed, press the button below to enable the open/closed sensor again.',
      buttons: {
        ok: 'Enable open/closed sensor'
      }
    },
    autoAccessBlockedByDevice: {
      title: 'Auto Access is disabled',
      text: 'Your device is opening the door in Auto Access mode more times per minute than our allowed limit.\n\nThis may indicate a bad connected between your device and the intercom or a misconfiguration of the door. We recommend you check the physical connection on the device and the configuration of the door.\n\nWhen you\'re sure the problem is fixed, press the button below to enable the Auto Access mode again.',
      buttons: {
        ok: 'Enable Auto Access mode'
      }
    },
    firmwareUpdateAvailable: {
      title: 'Update changes',
      text: '\n\nIMPORTANT\n\nMake sure your device has a good internet connection signal.\nIt is recommended to be near the device, so you can restart it in case something goes wrong.',
      buttons: {
        ok: 'Update now',
        cancel: 'Do it later'
      }
    },
    firmwareUpdateAvailableNotOwner: {
      title: 'Update available',
      text: 'Only the owner or an admin of the device can update it.\n\nIf you want the device to be updated, get in contact with any of the persons below.\n\n'
    },
    nukiBatteryCritical: {
      title: 'Nuki battery very low',
      text: 'The battery of your Nuki is very low. In any moment your Nuki will power off and stop responding. Replace them as soon as possible.\n\nWhen your Nuki has low battery, we can\'t guarantee the results of the operations because the Nuki does not behave as expected.'
    },
    remainingUpgrade: {
      title: 'Upgrade canceled',
      textFirst: 'Your device upgrade is ending ',
      textOwner: '. Remember, you can always upgrade your device again. You will not be charged until the paid period ends.',
      textNotOwner: '. Remember that you can ask the owner of the device to upgrade it again. You can see who\'s the owner on the Users tab.',
      buttons: {
        ok: 'Upgrade'
      }
    },
    doorPhoneUpgradeNotOwner: {
      title: 'Phone upgrade needed',
      text: 'Sorry, but you can\'t enable this feature right now because the owner of the device needs to upgrade this phone.\n\nOnly the owner of the device can upgrade it, you can check who\'s the owner on the Users tab.'
    },
    upgradesBillingAddress: {
      title: 'Invoices',
      text: 'If you want invoices, you just need to input your billing info. You will receive the invoices via email automatically each time your plan is charged.',
      buttons: {
        ok: 'Go to Billing'
      }
    },
    integrationNotSupported: {
      title: 'Integration not supported',
      text: 'Sorry, but this integration is not supported at this moment'
    },
    guestyPairDeviceNotUpgraded: {
      title: 'Device not upgraded',
      text: 'Sorry, but you can\'t pair your Guesty listing with this device because you haven\'t upgraded it',
      buttons: {
        ok: 'Upgrade'
      }
    },
    guestyPairDelete: {
      title: 'Delete upgrade',
      text: 'If you delete this upgrade, your Guesty listing won\'t synchronize with your Raixer device.\n\nWe\'ll also delete your associated plan of ',
      buttons: {
        ok: 'Delete upgrade'
      }
    },
    guestyPairDeleteConfirm: {
      title: 'Delete upgrade',
      text: 'Are you really sure you want to delete this upgrade?',
      buttons: {
        ok: 'Yes, delete upgrade'
      }
    },
    limitVariableFormNoValue: {
      title: 'No value detected',
      text: 'It seems your device does not detect changes in the electricity tension coming from the intercom.\n\nPlease, make sure you there is an ongoing intercom bell ring and that the connection between the intercom and your device is correct.\n\nTip: if this problem continues, consider inverting the cables between the intercom and your device.',
    },
    permissionExplanation: {
      title: 'Location permissions',
      text: 'We need your permission to access your location for obtaining your Wifi connection information and use it to configure your new Raixer device\n\nSince you\'ve already denied the permissions, if you want to enjoy this automatic configuration, you will have to go to your phone settings and give us the permissions manually.',
      buttons: {
        ok: 'Go to phone settings'
      }
    },
    cantFindWifiNetwork: {
      title: 'Connection help',
      text: 'If you can\'t find your WiFi network in the list, press "Refresh" as many times as you need to search again.\n\nRemember Raixer Devices only works with 2.4GHz networks. Many routers create two different WiFi networks: 2.4GHz and 5GHz. Make sure the 2.4GHz network is enabled in your router.'
    },
    cantFindPhoneNetwork: {
      title: 'Connection help',
      text: 'Your Raixer device can\'t be configured with the network your phone is connected to. Maybe the network is not within range or it\'s a 5GHz network. Remember that your Raixer is only compatible with 2.4GHz networks.'
    },
    cantConnectToDevice: {
      title: 'Connection help',
      text: 'If you can\'t connect to your device, try waiting for a notification from your phone that says the selected network does not have internet access and asks for confirmation to keep connected.\n\nPlease, press "Keep connected" when the notification appears and try again.\n\nIf the notification never appears, try forgetting the Raixer device Wi-Fi network and connecting to it again.'
    },
    wizardAddRaixerTap: {
      title: 'Add Raixer Tap',
      text: 'To add a new Raixer Tap to your device, just go to your device Settings screen and follow the instructions to add a new extra.'
    },
    wizardDeviceOnline: {
      title: 'Device online',
      text: 'Your device just came online, you can press on "Continue" to finish it\'s configuration.'
    },
    wizardDeviceOffline: {
      title: 'Device offline',
      text: 'Your device has not connected yet, but this can take a couple of minutes. Maybe, you need to wait a little longer to give your device time to connect.\n\nIf you want, you can start this process all over now.',
      buttons: {
        ok: 'Configure again'
      }
    },
    wizardDeviceOfflineConfirmation: {
      title: 'Device offline',
      text: 'Are you sure you want to start the configuration of your device all over again?',
      buttons: {
        ok: 'Configure again'
      }
    },
    wizardExitConfirmation: {
      title: 'Cancel device configuration',
      text: 'Are you sure you want to cancel the device configuration? You will stop and lose all the progress you have made.',
      buttons: {
        ok: 'Yes, cancel device configuration'
      }
    },
    saveLocationSuccess: {
      title: 'Location saved',
      text: 'Great! Your location was saved successfully and your data has been validated by the police force.',
      textSave: '\n\nYou can start now the use the service.',
      textEdit: '\n\nYou can continue your use of the service.'
    },
    saveLocationBadCredentials: {
      title: 'Save location error',
      text: 'Sorry, we were unable to save your location because the username and password you provided for the law enforcement is not correct.\n\nPlease check the username and password and try again.'
    },
    deleteLocation: {
      title: 'Delete location',
      text: 'Are you really sure you want to delete this location?',
      buttons: {
        ok: 'Delete location'
      }
    },
    bookingOverlappingMultipleSubLocations: {
      title: 'Couldn\'t save booking',
      text: 'Sorry, but we can\'t save this booking because your checkin Upgrade is on the trial period and because the booking\'s checkin or checkout dates overlap with another booking.\n\nIf you need to save this booking right away, you can get in contact with us at:\n\nhello@raixer.com'
    },
    deleteBooking: {
      title: 'Delete booking',
      text: 'Are you really sure you want to delete this booking? Deleting booking will delete autoaccess session and authorized phone numbers',
      buttons: {
        ok: 'Delete booking'
      }
    },
    energySaveInfo: {
      title: 'Power Save',
      text: 'Make sure that the noise made by your bell is strong enough to wake up your device before leaving it alone. To do it, enable Power Save, wait for your device light to turn off and call the bell to see if your device turns on again.',
    },
    energySaveEnableConfirm: {
      title: 'Enable Power Save',
      text: 'Are you sure? Your device will go to sleep immediately, you won\'t be able to control it from the app. To wake it up remotely, you will have to disable Power Save and wait 24 hours.'
    },
    energySaveTest: {
      title: 'Test Power Save',
      text: 'Your device will go to sleep immediately for 2 minutes or until it hears a sound (like a bell ring) or detects a movement. When it wakes up, it won\'t go back to sleep.'
    },
    noRaixerDevice: {
      title: 'Buy Raixer device',
      text: 'Buy a Raixer device and install it in your apartment to enjoy all the advantages of Raixer.\n\nYou can open your apartment door and the portal from anywhere. Also, the device have a built-in noise sensor (to detect parties). Your guests will do the checkin without you doing anything',
      buttons: {
        ok: 'Go to store',
        cancel: 'Later'
      }
    },
    paymentFailedNotOwner: {
      title: 'Device blocked',
      textFirst: 'Your device Upgrade payment is failing. You have ',
      textSecond: ' to correct this issue or we\'ll be forced to block your device.\n\nPlease, get in contact with the owner of the device to correct this issue.'
    },
    paymentFailedBlockedNotOwner: {
      title: 'Device blocked',
      text: 'We\'ve blocked your device because it\'s Upgrade is unpaid.\n\nPlease, get in contact with the owner of the device to correct this issue.'
    },
    dimissDeviceConfiguration: {
      title: 'Exit device configuration',
      text: 'Are you sure you want to exit your device configuration? You\'ll lose all the changes you\'ve made.',
      buttons: {
        ok: 'Yes, exit device configuration'
      }
    },
    dimissDeviceConfigurationConfirm: {
      title: 'Exit device configuration',
      text: 'Do you want to return here the next time you enter your device? In case you don\'t, you can always return to this process again from your device Settings.',
      buttons: {
        ok: 'Yes, I want to return here',
        cancel: 'No, I don\'t want to see this again'
      }
    },
    deleteAuthorizedCode: {
      title: 'Delete code',
      text: 'Are you sure you want to delete this code?',
      buttons: {
        ok: 'Delete'
      }
    },
    doorCodeRemove: {
      title: 'Delete door link',
      text: 'Are you sure you want to delete the link for opening your door?\n\nYou won\'t be able to open the door with a code. If you want to enable the link again, it won\'t be the same you have right now.',
      buttons: {
        ok: 'Delete'
      }
    },
    doorCodePossibleAnswers: {
      title: 'Information about the code',
      text: 'You\'re about to create a link to open the door',
      textEdit: 'You\'re about to change the link to open the door'
    },
    disableDoorCodeAccess: {
      title: 'Disable code access',
      text: 'Are you sure you want to disable code access? No one will be able to open the door entering a code.',
      buttons: {
        ok: 'Disable'
      }
    },
    enableDoorAnyCodeAccess: {
      title: 'Enable access without codes',
      text: 'Are you sure you want to enable access without codes? Anyone visiting the link will be able to open the door without the need to enter a code.',
      buttons: {
        ok: 'Enable'
      }
    },
    shareDoorCode: {
      title: 'Share door link',
      text: 'Copy the following text and share it anywhere you want:'
    },
    shareDoorPhone: {
      title: 'Share door phone',
      text: 'Copy the following text and share it anywhere you want:'
    },
    shareUserQr: {
      title: 'Share QR',
      text: 'Copy the following text and share it anywhere you want:'
    },
    shareBookingLink: {
      title: 'Share booking link',
      text: 'Copy the following text and share it anywhere you want:'
    },
    callSupport: {
      title: 'Technichal support',
      text: 'Having problems? We can help you, just give us a call at:\n\n+34910381221'
    },
    gyroNoInternet: {
      title: 'No internet',
      text: 'Your Gyro has no internet connection right now.\n\nRemember, you can always open the door with your phone via Bluetooth.'
    },
    buttons: {
      defaultOk: 'Got it',
      defaultCancel: 'Cancel'
    }
  },
  shares: {
    newUser: {
      title: 'Invitation for Raixer Device',
      message: 'Open this URL to gain access to the Raixer device',
      dialogTitle: 'Open this URL to gain access the Raixer device',
      deviceId: 'Device: ',
      name: 'Name: '
    },
    doorPhone: {
      title: 'Call to open the door',
      message: 'Call the following number to open the door'
    },
    deviceId: {
      title: 'Device Information',
      message1: 'Device ID: ',
      message2: 'Device version: ',
      message3: 'Device OS version: ',
      message4: 'Device firmware version: '
    },
    booking: {
      title: 'Invitation for register',
      message: 'Sign up for your next visit',
      message1: 'Sign up for your next visit to ',
      message2: 'at ',
      dialogTitle: '',
    },
    doorCode: {
      title: 'Open the door',
      message: 'Enter here to open the door',
      message2: 'and input the code'
    }
  },
  screens: {
    auth: {
      login: 'Login',
      signup: 'Sign up',
      recoverPassword: 'Reset password',
      facebookLogin: 'Login with Facebook',
      facebookSignup: 'Signup with Facebook',
      appleLogin: 'Login with Apple',
      textTerms: 'By signing up, you agree to our',
      linkSignup: 'New to Raixer? Sign up',
      linkRecover: 'Recover password',
      linkLoginSignup: 'Already got an account? Login',
      linkLoginRecover: 'Go back',
      linkTerms: 'terms and conditions'
    },
    connections: {
      title: 'My places',
      loading: 'Loading your places',
      search: 'Search by name',
      emptySearch: 'No places found with this name',
      empty: 'Want to add a Raixer?',
      emptyButton: 'New device',
      emptyButtonSecondary: 'Buy device',
      emptyCheckin: 'Want to add a location for our checkins integration but don\'t have a Raixer?',
      emptyCheckinButton: 'Checkin document scanner'
    },
    connection: {
      title: 'My place details',
      empty: 'Click on one of your places in the list on the left\nto control it here',
      fetching: 'Obtaining your device...',
      lastMovement: 'Last movement: ',
      autoAccessAlwaysActive: 'Auto Access is Always Active',
      pistonActive: 'The piston is active',
      pistonInactive: 'The piston is deactivated',
      sessionEnd: 'until session ends',
      contingencyMode: 'Contingency mode',
      alwaysActive: 'Always active',
      autoAccessAlwaysActiveDescription: 'Auto Access is Always Active, turn it off if you prefer to schedule Auto Access sessions',
      schedulesEmpty: 'No scheduled Auto Access for this device',
      schedulesAddButton: 'Schedule',
      usersAddButton: 'Add user',
      unexpectedError: 'Unexpected error, pull down to refresh your device and try again',
      historyEmpty: 'No history found for this device',
      historyEmptyTrialEnded: 'Upgrade to unlock history. You can check all movements, even those prior to the upgrade.',
      warnings: {
        offline: 'Your device is offline or does not have internet connection.',
        updating: 'Your device is updating, this usually takes a couple of minutes.',
        offlineAutoAccess: 'Contingency Mode: Offline Auto Access active.',
        cantUse: 'Unlock features',
        cantUseAccount: 'Upgrade the device to keep using it.',
        paymentFailing: 'Your payment method is failing.',
        paymentFailingNotOwner: 'Your device Upgrade is unpaid.',
        paymentFailedBlocked: 'Your device is blocked because your payment method is failing.',
        paymentFailedBlockedNotOwner: 'Your device is blocked because it\'s Upgrade is unpaid.',
        apiUserPaymentFailed: 'Your payment to use the API failed. Please, contact us to keep using your devices.',
        trialExpireToday: 'Your trial expires today',
        remainingTrial: 'Trial remaining',
        afterTrialFreeVersion: 'Then you will be on the free version.',
        openClosedBlocked: 'Door open/closed sensor disabled',
        autoAccessBlocked: 'Auto Access is disabled',
        updateAvailable: 'Device update available',
        nukiBatteryCritical: 'The battery of your Nuki is very low',
        noRaixerDevice: 'Buy a Raixer device',
        linkRaixerDevice: 'Link one of your devices',
        remainingUpgrade: 'Upgrade remaining',
        upgradeExpireToday: 'Your upgrade expires today',
        particleDisabled: 'Your device is no longer upgraded and cannot be used',
        signal: {
          strong: 'Strong',
          good: 'Good',
          fair: 'Fair',
          bad: 'Bad',
          poor: 'Poor',
          wifi: 'WiFi signal:',
          cellular: 'Cellular signal:'
        },
        power: {
          electricity: 'Connected to electricity',
          usingBattery: 'Using battery'
        },
        battery: {
          battery: 'Battery',
          gyroBattery: 'Gyro battery',
          charging: 'Charging',
          discharging: 'Discharging',
          charged: 'Charged',
          disconnected: 'Disconnected',
          lastUpdatedAt: 'Last updated:'
        },
        gyroConnectWifi: 'Connect to WiFi',
        gyroControlIntercom: 'Control your intercom',
        gyroNoInternet: 'No internet connection',
        afterGyroTrial: 'Then you will be in your plan'
      },
      error: {
        disabled: 'You don\'t have permissions to use this device. Contact the owner to ask for authorization.',
        unexpected: 'Oops! An unexpected error happened.\nPlease, pull down to try again.',
        trialEnded: 'Please upgrade your device to access it\'s features.'
      },
      users: {
        search: 'Search by name or email',
        emptySearch: 'No users found with this name or email',
        of: 'of'
      },
      phones: {
        noPhone: 'This door does not have a phone number',
        getPhone: 'Get a number',
        addAuthorizedPhone: 'Add authorized number',
        callingEnabledLabel: 'Active',
        anyCallerEnabledLabel: 'Access to any caller',
        authorizedPhonesTitle: 'Authorized numbers',
        authorizedPhonesEmpty: 'If you don\'t authorize any numbers, no one can open the door.',
        disabled: 'The phone access for this door is disabled. Turn on the switch above to enable it.'
      },
      codes: {
        noLink: 'This door does not have a link',
        getLink: 'Get a link',
        addAuthorizedCode: 'New code',
        callingEnabledLabel: 'Active',
        authorizedCodesTitle: 'Authorized codes',
        authorizedCodesEmpty: 'If you don\'t authorize any code, no one can open the door.',
        anyCallerEnabledLabel: 'Codeless access',
        search: 'Search by name or code',
        emptySearch: 'No codes found with this name or code',
        of: 'of'
      },
      history: {
        phoneCall: {
          success: 'Door 1 - Phone access',
          error: 'Door 1 - Unauthorized phone access'
        },
        phoneCall2: {
          success: 'Door 2 - Phone access',
          error: 'Door 2 - Unauthorized phone access'
        },
        phoneCall3: {
          success: 'Door 3 - Phone access',
          error: 'Door 3 - Unauthorized phone access'
        },
        phoneCallOffline: {
          error: 'Door 1 - Phone access unavailable'
        },
        phoneCallOffline2: {
          error: 'Door 2 - Phone access unavailable'
        },
        phoneCallOffline3: {
          error: 'Door 3 - Phone access unavailable'
        },
        openDoor: {
          success: 'Door 1 - Opened with app',
          error: 'Door 1 - Unauthorized user'
        },
        openDoor2: {
          success: 'Door 2 - Opened with app',
          error: 'Door 2 - Unauthorized user'
        },
        openDoor3: {
          success: 'Door 3 - Opened with app',
          error: 'Door 3 - Unauthorized user'
        },
        startJob: {
          success: 'Door 1 - Start Auto Access',
          error: 'Door 1 - Unauthorized user'
        },
        startJob2: {
          success: 'Door 2 - Start Auto Access',
          error: 'Door 2 - Unauthorized user'
        },
        startJob3: {
          success: 'Door 3 - Start Auto Access',
          error: 'Door 3 - Unauthorized user'
        },
        stopJob: {
          success: 'Door 1 - End Auto Access',
          error: 'Door 1 - Unauthorized user'
        },
        stopJob2: {
          success: 'Door 2 - End Auto Access',
          error: 'Door 2 - Unauthorized user'
        },
        stopJob3: {
          success: 'Door 3 - End Auto Access',
          error: 'Door 3 - Unauthorized user'
        },
        doorOpened: {
          success: 'Door 1 - Opened with Auto Access',
          error: 'Door 1 - The door was not opened when someone called'
        },
        doorOpened2: {
          success: 'Door 2 - Opened with Auto Access',
          error: 'Door 2 - The door was not opened when someone called'
        },
        doorOpened3: {
          success: 'Door 3 - Opened with Auto Access',
          error: 'Door 3 - The door was not opened when someone called'
        },
        doorIsOpen: {
          success: 'Door 1 - Open',
          error: 'Door 1 - Not opened'
        },
        doorIsOpen2: {
          success: 'Door 2 - Open',
          error: 'Door 2 - Not opened'
        },
        doorIsOpen3: {
          success: 'Door 3 - Open',
          error: 'Door 3 - Not opened'
        },
        doorIsClosed: {
          success: 'Door 1 - Closed',
          error: 'Door 1 - Not closed'
        },
        doorIsClosed2: {
          success: 'Door 2 - Closed',
          error: 'Door 2 - Not closed'
        },
        doorIsClosed3: {
          success: 'Door 3 - Closed',
          error: 'Door 3 - Not closed'
        },
        startJobAutoAccess: {
          success: 'Door 1 - Start scheduled Auto Access',
          error: 'Door 1 - Error Start scheduled Auto Access'
        },
        startJobAutoAccess2: {
          success: 'Door 2 - Start scheduled Auto Access',
          error: 'Door 2 - Error Start scheduled Auto Access'
        },
        startJobAutoAccess3: {
          success: 'Door 3 - Start scheduled Auto Access',
          error: 'Door 3 - Error Start scheduled Auto Access'
        },
        stopJobAutoAccess: {
          success: 'Door 1 - End scheduled Auto Access',
          error: 'Door 1 - Error Start scheduled Auto Access'
        },
        stopJobAutoAccess2: {
          success: 'Door 2 - End scheduled Auto Access',
          error: 'Door 2 - Error Start scheduled Auto Access'
        },
        stopJobAutoAccess3: {
          success: 'Door 3 - End scheduled Auto Access',
          error: 'Door 3 - Error Start scheduled Auto Access'
        },
        deleteJobAutoAccess: {
          success: 'Door 1 - Deleted scheduled Auto Access after several retries',
          error: 'Door 1 - Deleted scheduled Auto Access after several retries'
        },
        deleteJobAutoAccess2: {
          success: 'Door 2 - Deleted scheduled Auto Access after several retries',
          error: 'Door 2 - Deleted scheduled Auto Access after several retries'
        },
        deleteJobAutoAccess3: {
          success: 'Door 3 - Deleted scheduled Auto Access after several retries',
          error: 'Door 3 - Deleted scheduled Auto Access after several retries'
        },
        deviceOffline: {
          success: 'Device offline'
        },
        deviceOnline: {
          success: 'Device online'
        },
        movementDetected: {
          success: 'Movement detected'
        },
        physicalButton: {
          success: 'Physical button pressed'
        },
        powerIn: {
          success: 'Power restored - Charging battery'
        },
        powerOut: {
          success: 'Power outage - Using battery'
        },
        pistonActivated: {
          success: 'Piston active'
        },
        pistonDeactivated: {
          success: 'Piston deactivated'
        },
        offlinePistonOpeningStarted: {
          success: 'Device offline - Start procedure to open piston'
        },
        offlinePistonOpeningEnded: {
          success: 'Device offline - Piston opened'
        },
        soundDetected: {
          success: 'Sound detected'
        },
        soundAlarm: {
          success: 'Sound alarm'
        },
        openDoorAssistant: {
          success: 'Door 1 - Opened with Google or Alexa',
          error: 'Door 1 - Unauthorized user with Google or Alexa'
        },
        openDoorAssistant2: {
          success: 'Door 2 - Opened with Google or Alexa',
          error: 'Door 2 - Unauthorized user with Google or Alexa'
        },
        openDoorAssistant3: {
          success: 'Door 3 - Opened with Google or Alexa',
          error: 'Door 3 - Unauthorized user with Google or Alexa'
        },
        sleep: {
          success: 'Device asleep'
        },
        wokeup: {
          success: 'Device woke up'
        },
        energySaveActivated: {
          success: 'Energy Save enabled'
        },
        energySaveDeactivated: {
          success: 'Energy Save disabled'
        },
        accessByCode: {
          success: 'Door 1 - Opened by code'
        },
        accessByCode2: {
          success: 'Door 2 - Opened by code'
        },
        accessByCode3: {
          success: 'Door 3 - Opened by code'
        },
        accesssByCodeOffline: {
          error: 'Code access unavailable'
        },
        nukiUnlock: {
          success: 'Nuki unlocked',
          error: 'Error unlocking Nuki'
        },
        nukiClose: {
          success: 'Nuki closed',
          error: 'Error closing Nuki'
        },
        nukiOpen: {
          success: 'Nuki opened',
          error: 'Error opening Nuki'
        },
        bellRing: {
          success: 'Door 1 - Bell ring'
        },
        bellRing2: {
          success: 'Door 2 - Bell ring'
        },
        bellRing3: {
          success: 'Door 3 - Bell ring'
        },
        gyroUnlock: {
          success: 'Key[doorName]opened with app',
          error: 'Error opening key[doorName]'
        },
        gyroClose: {
          success: 'Door[doorName]closed with app',
          error: 'Error closing door[doorName]'
        },
        gyroOpen: {
          success: 'Door[doorName]opened with app',
          error: 'Error opening door[doorName]'
        },
        gyroOpenPhoneCall: {
          success: 'Phone access[doorName]',
          error: 'Error on phone access[doorName]'
        },
        gyroOpenAuthCode: {
          success: 'Opening with code[doorName]',
          error: 'Error opening with code[doorName]'
        }
      }
    },
    autoAccessForm: {
      title: 'Auto Access configuration',
      titleEdit: 'Edit Auto Access configuration',
      labels: {
        alwaysActive: 'Always active',
        contingencyMode: 'Contingency Mode'
      },
      helps: {
        alwaysActive: 'Auto Access is always active',
        contingencyMode: 'Activate Auto Access when the device has no internet'
      }
    },
    scheduleForm: {
      title: 'New schedule',
      titleEdit: 'Edit schedule',
      titleForm: 'New schedule rule',
      titleFormEdit: 'Edit schedule rule',
      labels: {
        startDate: 'Start date',
        startTime: 'Start access at',
        endDate: 'End date',
        endTime: 'End access at'
      }
    },
    userForm: {
      title: 'New user',
      titleEdit: 'Edit user',
      titleForm: 'Share QR Code to add an user',
      titleFormEdit: 'Edit user',
      newUserMainButton: 'Share',
      errorGetQrs: 'Unexpected error getting the QR codes of your device. Go back and try again.',
      enableUser: 'Enable',
      disableUser: 'Disable',
      labels: {
        startDate: 'Start date',
        startTime: 'Start access at',
        endDate: 'End date',
        endTime: 'End access at'
      }
    },
    notifications: {
      labels: {
        confirmDeviceOpenClose: 'Confirm device opening and closing'
      },
      helps: {
        confirmDeviceOpenClose: 'If enabled, a confirmation will be requested before opening or closing the device'
      }
    },
    authorizedCodeForm: {
      title: 'New code',
      titleEdit: 'Edit code',
      titleForm: 'New authorized code',
      titleFormEdit: 'Edit authorized code',
      labels: {
        code: 'Code',
        unlimitedAccess: 'Unlimited access time',
        deleteOnEnd: 'Delete on end',
        repeatDays: 'Repeat on days of week',
        alias: 'Alias (optional)'
      },
      helps: {
        code: 'Remember to write the code with 6 digits max and numeric only'
      }
    },
    codeDoor: {
      title: 'Door code',
      titleNew: 'Get a code for',
      titleEdit: 'Change the code for',
      text: 'Choose an alias for your new url',
      optionalAlias: 'Alias (Optional)',
      buttons: {
        delete: 'Delete link',
        generateLink: 'Generate link'
      }
    },
    authorizedPhoneForm: {
      title: 'New phone',
      titleEdit: 'Edit phone',
      titleForm: 'New authorized phone',
      titleFormEdit: 'Edit authorized phone',
      labels: {
        phone: 'Phone',
        unlimitedAccess: 'Unlimited openings',
        deleteOnCall: 'Delete number after opening',
        deleteOnCallAfter: 'Allowed openings before deleting',
        deleteOnEnd: 'Delete on end',
        repeatDays: 'Repeat on days of week',
        alias: 'Alias (optional)'
      },
      helps: {
        phone: 'Remember to write the phone with the country code'
      }
    },
    phoneDoor: {
      title: 'Door phone',
      titleNew: 'Get a phone',
      titleEdit: 'Change phone number',
      text: 'Select the country you prefer for your new number. The door will open with missed call. The call will be rejected and will have no cost regardless of the country you choose.',
      textOtherDevice: 'Using the same phone number for several doors allows to open them with just one call.\n\nPress on one of the phone numbers you have in your other devices',
      textNoPhones: 'Sadly, we don\'t have any availables phone numbers for you. Please, contact us at hello@raixer.com to help you.',
      textError: 'An unexpected error ocurred obtaining the available countries. Go back and try again.',
      helps: {
        useOtherDevicePhone: 'Use one of my other devices numbers'
      },
      buttons: {
        delete: 'Remove phone'
      }
    },
    phoneDoorChangeConfirm: {
      titleAccessToAnyCaller: 'Access to any caller',
      titleCardAccessToAnyCaller: 'Confirm access to any caller',
      textAccessToAnyCaller: 'If you enable this feature, anyone who calls the number below will open the door.\n\nIt seems we can\'t enable this feature for your previous number because it was already in use by other devices. If you want to enable this, you\'ll have to use the number below.',
      titleAuthorizedPhone: 'Authorized phone',
      titleCardAuthorizedPhone: 'Authorized phone was not saved',
      textAuthorizedPhone: 'You have reached the maximum number of authorized phone numbers for a shared number.\n\nYou need a private phone in order to add more authorized phones.\n\nYou need to change and upgrade your door phone number to the one below.',
      buttons: {
        authorizedPhone: 'Change and upgrade number'
      }
    },
    phonesInformation: {
      title: 'Phone information',
      titleSecond: 'Phone utilisation',
      textFirst: 'Remember that the door will open even if the answer to the call is:\n\n1. The call is rejected.\n2. The phone does not exist.',
      textSecond: 'Your door phone is also used by one or more of your other devices.\n\nKeep in mind that if the same authorized phone is in more that one door, if that number calls, it will open all doors.\n\nBelow, you can see the list of the doors of your other devices using it, as well as the authorized phones, if any, that are also in that doors and in this one.'
    },
    upgradeForm: {
      titleNew: 'Select your plan',
      titleEdit: 'Change your plan',
      planNull: 'Select an option...'
    },
    creditCard: {
      title: 'Payment method',
      validThru: 'Valid Thru',
      error: 'Unexpected error obtaining your info from our payment operator. Please, go back and try again.',
      submit: 'Save card',
      delete: 'Delete card',
      labels: {
        number: 'Credit card number',
        name: 'Carholder name',
        expirationMonth: 'Expiration month',
        expirationYear: 'Expiration year',
        cvc: 'CVC'
      }
    },
    workInProgress: {
      title: 'Work in progress',
      text: 'We\'re currently working in this feature, you\'ll have it very soon. Please, go to our APP on Android or iOS if you need to do this now.',
      button: 'Got it'
    },
    checkin: {
      locationEmpty: 'No location registered for this connection',
      locationAddButton: 'Add location',
      locationGuestBook: 'Guest book',
      bookingEmpty: 'No further bookings booked',
      bookingAddButton: 'Add booking',
      bookingPast: 'Past bookings'
    },
    locationForm: {
      titleEdit: 'Location settings',
      title: 'New location',
      labels: {
        locationType: 'Location type',
        locationName: 'Location name',
        locationAddress: 'Location address',
        cifnif: 'CIF / NIF',
        locationTown: 'Town',
        locationProvince: 'Province',
        police: 'Police',
        userPolice: 'User',
        passPolice: 'Password',
        landlordCode: 'Landlord code',
        establishmentCode: 'Establishment code',
        sequenceNumber: 'Sequence number',
        andaluciaCode: 'Enrollment code in the Council of Andalucía',
        locationCategory: 'Location category',
        landlordPhoneNumber: 'Landlord phone',
        landlordName: 'Landlord name',
        startDate: 'From',
        switchStartHour: 'Arrival time',
        startHour: 'At',
        endDate: 'To',
        residency: 'Nights',
        guests: 'Number of guests',
        guestName: 'Guest name',
        notificationEmail: 'Email for notifications',
        startTime: 'Start Auto Access at',
        endTime: 'End Auto Access at',
        postalCode: 'Postal code',
        numberOfBedrooms: 'Number of bedrooms',
        paymentType: 'Payment type',
      },
      helps: {
        startDate: 'Date of checkin',
        startHour: 'Hour of checkin',
        switchStartHour: 'I know the arrival time',
        residency: 'Total number of days of the visit',
        endDate: 'Guest departure date. This date is updated when the number of nights is modified',
        guests: 'Total number of guests',
        guestName: 'Name of the main guest',
        notificationEmail: 'Email to send notifications',
        locationType: 'Select the type of your location',
        locationName: 'Enter the name with which you have registered this location in the police',
        locationAddress: 'Location address',
        locationCategory: 'Category to which the location belongs',
        andaluciaCode: 'Enrollment code in the Council of Andalucía',
        landlordPhoneNumber: 'Phone number of the landlord',
        landlordName: 'Name of the landlord or manager',
        cifnif: 'NIF/CIF of the owner, landlord or manager',
        locationTown: 'Town where the location is located',
        locationProvince: 'Province where the location is located',
        police: 'Police where you have registered your location',
        userPolice: 'Username in the police system. If you do not have a username you must first register the location with the police',
        passPolice: 'Password of your account in the police system',
        landlordCode: 'Landlord code',
        establishmentCode: 'Establishment code',
        sequenceNumber: 'Write the number of the last report generated. The next report number will be: ',
        autoAccess: 'Create Auto Access sessions automatically when a reservation is made',
        phoneNumbers: 'Add guest phone numbers to open the door',
        signConditionsDocument: 'Require the guests to read and accept a document containing conditions for the stay',
        internet: 'Indicates if the accommodation has an internet connection',
        professional: 'Indicate if the accommodation is managed by a company or a self-employed individual',
        postalCode: 'Postal code of the location',
        numberOfBedrooms: 'Number of bedrooms',
        paymentType: 'Select the payment method',
        automation: 'Automation'
      },
      buttons:{
        selectConditionsDocument: 'Select document',
        changeConditionsDocument: 'Select another',
        viewConditionsDocument: 'View document'
      }
    },
    bookingForm: {
      titleEdit: 'Edit Booking',
      title: 'New Booking',
      labels: {
        startDate: 'Date of checkin',
        startHour: 'Arrival time',
        switchStartHour: 'Arrival time',
        residency: 'Total number of nights',
        endDate: 'Guest departure date',
        guests: 'Number of guests'
      },
      help: {
        startDate: 'Date of checkin',
        startHour: 'Time of the arrival',
        switchStartHour: 'I know the arrival time of the guest',
        residency: 'Total number of nights for the stay',
        endDate: 'This date is updated when the number of nights is modified',
        guests: 'Total number of guests during the stay'
      }
    },
    bookingHistory: {
      title: 'Past bookings',
      textEmpty: 'There are no past bookings'
    },
    guestBook: {
      title: 'Guest Book',
      textEmpty: 'There are no records yet',
      send: 'Send'
    },
    guestForm: {
      textEmpty: 'No guest has checked in yet'
    },
    verifyEmailForm: {
      title: 'Verify email',
      codeLabel: 'Code',
      codePlaceholder: 'Enter the code'
    }
  }
}