import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import t from 'tcomb-form'

import UserActions from '../../../Redux/UserRedux'
import I18n from '../../../i18n/i18n'
import ContentCard from '../../../Components/UI/ContentCards/ContentCard'
import FormCard from '../../../Components/UI/ContentCards/FormCard'
import styles from '../../../Components/Styles/Screens/Forms'

const VerifyEmailForm = ({ fetching, onSubmit }) => {
  const [codeValue, setCodeValue] = useState({ code: '' })

  const getCodeFields = () => ({
    code: t.String
  })

  const getCodeFieldsOptions = () => ({
    code: {
      label: I18n.t('screens.verifyEmailForm.codeLabel'),
      placeholder: I18n.t('screens.verifyEmailForm.codePlaceholder'),
      autoCapitalize: 'none',
      autoCorrect: false
    }
  })

  const onSubmitCode = () => {
    onSubmit(codeValue)
  }

  const onChangeCode = (value) => {
    setCodeValue(value)
  }

  return (
    <div style={styles.mainBody}>
      <FormCard
        name={'verifyEmail'}
        title={I18n.t('screens.verifyEmailForm.title')}
        onSubmit={onSubmitCode}
        onChange={onChangeCode}
        deleteButton={false}
        fields={getCodeFields()}
        fieldsOptions={getCodeFieldsOptions()}
        value={codeValue}
        fetching={fetching}
        submitButtonDisabled={!codeValue.code}
      />
      {fetching && <div>Loading...</div>}
    </div>
  )
}

const VerifyEmailScreen = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.user)
  const fetching = useSelector(state => state.user.fetchingForm)

  const verifyEmail = (data) => {
    dispatch(UserActions.verifyEmailRequest(data.code))
  }

  return (
    <ContentCard
      renderContent={() => (
        <VerifyEmailForm
          fetching={fetching}
          onSubmit={verifyEmail}
        />
      )}
    />
  )
}

export default VerifyEmailScreen