import React, { Component } from 'react'
import { connect } from 'react-redux'

import t from 'tcomb-form'
import I18n from '../../i18n/i18n'
import ContentCard from '../../Components/UI/ContentCards/ContentCard'
import BaseSwitch from '../../Components/UI/Switchs/BaseSwitch'
import FormCard from '../../Components/UI/ContentCards/FormCard'
import styles from '../../Components/Styles/Screens/Forms'

import { Metrics } from '../../Themes'
import UserActions from '../../Redux/UserRedux'

class NotificationsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDeviceOpenClose: true,
      value: {
        email: ''
      }
    }
  }

  componentDidMount() {
    const { userSettings, user } = this.props
    this.setState({
      ...userSettings,
      value: {
        email: user?.email || ''
      }
    })
  }

  getFields = () => {
    return {
      email: t.String
    }
  }

  getFieldsOptions = () => {
    return {
      email: {
        label: I18n.t('shared.email'),
        autoCapitalize: 'none',
        autoCorrect: false,
        keyboardType: 'email-address',
        customTemplate: true
      },
    }
  }

  onEmailChange = (value) => {
    this.setState({ value })
  }

  onEmailSubmit = () => {
    const { user } = this.props
    const { value } = this.state
    const updatedValue = {
      ...(user?.email !== value.email && { email: value.email })
    }
    this.props.updateUserValue(user, updatedValue)
  }

  onConfirmDeviceOpenCloseChange = (value) => {
    this.setState({ confirmDeviceOpenClose: value })
    const type = 'confirmDeviceOpenClose'
    const { user } = this.props
    const newSettings = { ...user.settings, [type]: value }
    const updatedValue = {
      settings: newSettings,
      ...(user.email !== this.state.value.email && { email: this.state.value.email })
    }
    this.props.updateUserValue(user, updatedValue)
  }

  renderSwitch = (type, value, onChangeFunction, first = false, last = false) => {
    return (
      <>
        <BaseSwitch
          value={value}
          disabled={false}
          label={I18n.t('screens.notifications.labels.' + type)}
          help={I18n.t('screens.notifications.helps.' + type)}
          onChange={onChangeFunction}
          customSwitchRowStyle={styles.switchRow}
          customHelpStyle={styles.switchHelpSmall}
          customLabelStyle={first ? { marginTop: 0 } : null}
          customSwitchStyle={styles.switch}
          withoutMargin={true}
        />
        <div style={last ? styles.listItem : Object.assign({}, styles.listItem, styles.lastListItem)} />
      </>
    )
  }

  renderSettings = () => {
    return (
      <div style={{paddingTop: Metrics.smallMargin, paddingBottom: Metrics.smallMargin}}>
        { this.renderSwitch('confirmDeviceOpenClose', this.state.confirmDeviceOpenClose, this.onConfirmDeviceOpenCloseChange, true, true) }
      </div>
    )
  }

  render = () => {
    return (
      <ContentCard
        renderContent={() => (
          <>
            <FormCard
              title={I18n.t('shared.generalSettings')}
              name={'emailForm'}
              onSubmit={this.onEmailSubmit}
              onChange={this.onEmailChange}
              deleteButton={false}
              fields={this.getFields()}
              fieldsOptions={this.getFieldsOptions()}
              renderExtraContentBeforeButtons={this.renderSettings}
              value={this.state.value}
              fetching={this.props.fetching}
            />
          </>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    userSettings: state.user.userSettings,
    fetching: state.user.fetching
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserValue: (user, fields) => dispatch(UserActions.userSaveRequest(user, fields))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsScreen)