import { call, put, select } from 'redux-saga/effects'
import I18n from '../i18n/i18n'
import { I18nSelectors } from '../Redux/I18nRedux'
import { AuthSelectors } from '../Redux/AuthRedux'
import UserActions from '../Redux/UserRedux'
import { AlertService } from '../Services/Alert'

export function * getUser (api) {
  const userId = yield select(AuthSelectors.selectUserId)
  const response = yield call(api.getUser, userId)
  const { ok, data: user } = response
  if (ok) {
    yield put(UserActions.userRequestFinished(user, null))
  } else {
    yield put(UserActions.userRequestFinished(null, true))
    AlertService.error(I18n.t('snacks.errors.getUser'))
  }
}

export function * updateUser (api, action) {
  const { user, fields } = action
  const { _id } = user
  const activeLang = yield select(I18nSelectors.selectActiveLang)
  const response = yield call(api.updateUser, _id, { ...fields, activeLang })
  const { ok, data: newUser, status } = response
  if (ok) {
    yield put(UserActions.userRequestFinished(newUser, null))
    AlertService.success(I18n.t('snacks.success.updateUser'))
    if (fields.hasOwnProperty('email')) {
      yield put(UserActions.setShowVerifyEmail(true))
    }
  } else {
    yield put(UserActions.userRequestFinished(user, null))
    if (status === 409 && newUser?.msg) {
      AlertService.error(I18n.t(newUser.msg) ?? I18n.t('snacks.errors.updateUser'))
    } else {
      AlertService.error(I18n.t('snacks.errors.updateUser'))
    }
  }
}

export function * verifyEmail (api, action) {
  const { code } = action
  const userId = yield select(AuthSelectors.selectUserId)
  const response = yield call(api.verifyEmail, userId, { code })
  const { ok, data: newUser, status } = response

  if (ok) {
    yield put(UserActions.userRequestFinished(newUser, null))
    AlertService.success(I18n.t('snacks.success.updateUser'))
    yield put(UserActions.setShowVerifyEmail(false))
  } else {
    yield put(UserActions.verifyEmailFinished())
    if (status === 400 && newUser?.msg) {
      AlertService.error(I18n.t(newUser.msg) ?? I18n.t('snacks.errors.updateUser'))
    } else {
      AlertService.error(I18n.t('snacks.errors.updateUser'))
    }
  }
}