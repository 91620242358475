import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userRequest: null,
  userSaveRequest: ['user', 'fields'],
  userRequestFinished: ['user', 'error'],
  verifyEmailRequest: ['code'],
  verifyEmailFinished: null,
  setShowVerifyEmail: ['show']
})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  user: null,
  userSettings: null,
  fetching: false,
  fetchingForm: false,
  fetchingEmailVerification: false,
  error: null,
  showFormVerifyEmail: false
})

/* ------------- Selectors ------------- */
export const UserSelectors = {
  selectUser: state => state.user.user
}

/* ------------- Reducers ------------- */

export const userRequest = (state) => {
  return state.merge({ 
    fetching: true,
    error: null
  })
}

export const userSaveRequest = (state) => {
  return state.merge({ 
    fetchingForm: true,
    error: null
  })
}

export const userRequestFinished = (state, action) => {
  const { user, error } = action
  const userSettings = user && user.settings

  return state.merge({ 
    fetching: false,
    fetchingForm: false,
    fetchingEmailVerification: false,
    error, 
    user,
    userSettings
  })
}

export const verifyEmailRequest = (state) => {
  return state.merge({
    fetchingEmailVerification: true,
    error: null
  })
}

export const verifyEmailFinished = (state) => {
  return state.merge({
    fetchingEmailVerification: false,
    error: null
  })
}

export const setShowVerifyEmail = (state, action) => {
  return state.merge({
    showFormVerifyEmail: action.show
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_REQUEST]: userRequest,
  [Types.USER_SAVE_REQUEST]: userSaveRequest,
  [Types.USER_REQUEST_FINISHED]: userRequestFinished,
  [Types.VERIFY_EMAIL_REQUEST]: verifyEmailRequest,
  [Types.VERIFY_EMAIL_FINISHED]: verifyEmailFinished,
  [Types.SET_SHOW_VERIFY_EMAIL]: setShowVerifyEmail
})
